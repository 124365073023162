import actions from "./actions";

const initState = {
  charts: [],
  maps: [],
  loading: false,
  customer: {},
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    //#region DASHBOARD
    case actions.GET_DASHBOARD_DETAIL:
      return { ...state, loading: true };

    case actions.GET_DASHBOARD_DETAIL_SUCCESS: {
      let { data, charts } = action.payload;
      let res = data?.length ? data : [];
      let dataChart = charts?.length ? charts : [];
      return {
        ...state,
        maps: res,
        charts: dataChart,
        loading: false,
      };
    }
    case actions.GET_DASHBOARD_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        map: [],
        charts: [],
      };

    // CUSTOMER
    case actions.GET_CUSTOMER_DASHBOARD:
      return { ...state, loading: true };

    case actions.GET_CUSTOMER_DASHBOARD_SUCCESS: {
      const { data } = action.payload;
      let res = data?.alertId ? data : {};
      return {
        ...state,
        customer: res,
        loading: false,
      };
    }
    case actions.GET_CUSTOMER_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        customer: {},
      };

    default:
      return state;
  }
}
