const options = [
  {
    key: 'chat',
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
  },
  {
    key: 'ecommerce',
    label: 'sidebar.ecommerce',
    leftIcon: 'ion-bag',
    children: [
      {
        key: 'shop',
        label: 'sidebar.shop',
      },
      {
        key: 'cart',
        label: 'sidebar.cart',
      },
      {
        key: 'checkout',
        label: 'sidebar.checkout',
      },
      {
        key: 'card',
        label: 'sidebar.cards',
      },
    ],
  },
  {
    key: 'maps',
    label: 'sidebar.maps',
    leftIcon: 'ion-map',
    children: [
      {
        key: 'googlemap',
        label: 'sidebar.googleMap',
      },
      {
        key: 'leafletmap',
        label: 'sidebar.leafletMap',
      },
    ],
  },
  {
    key: 'blank_page',
    label: 'sidebar.blankPage',
    leftIcon: 'ion-document',
  },
];
export default options;
