import actions from "./actions";

const initState = { idToken: null, resetMenu: false };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
      };

    case actions.LOGOUT:
      return initState;

    case actions.RESET_MENU:
      return { ...state, resetMenu: !state.resetMenu };

    default:
      return state;
  }
}
