const actions = {
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",

  GET_USER_DETAIL: "GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_ERROR: "GET_USER_DETAIL_ERROR",

  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_ERROR: "GET_CUSTOMERS_ERROR",

  GET_CUSTOMER_DETAIL: "GET_CUSTOMER_DETAIL",
  GET_CUSTOMER_DETAIL_SUCCESS: "GET_CUSTOMER_DETAIL_SUCCESS",
  GET_CUSTOMER_DETAIL_ERROR: "GET_CUSTOMER_DETAIL_ERROR",

  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",

  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  USER_RESET_PASSWORD: "USER_RESET_PASSWORD",

  DELETE_USER: "DELETE_USER",

  USER_ACCESS_PERMISSION: "USER_ACCESS_PERMISSION",
  USER_ACCESS_PERMISSION_SUCCESS: "USER_ACCESS_PERMISSION_SUCCESS",
  USER_ACCESS_PERMISSION_ERROR: "USER_ACCESS_PERMISSION_ERROR",

  UPDATE_USER_ACCESS_PERMISSION: "UPDATE_USER_ACCESS_PERMISSION",

  SAVED_USER_TYPE: "SAVED_USER_TYPE",
  //#region CRUD
  getUsers: (model, _cbError) => ({
    type: actions.GET_USERS,
    payload: { model },
    _cbError,
  }),

  deleteUser: (model, cbSuccess, cbError) => ({
    type: actions.DELETE_USER,
    payload: { model },
    cbSuccess,
    cbError,
  }),

  getUserDetail: (id) => ({
    type: actions.GET_USER_DETAIL,
    payload: { id },
  }),

  createUser: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_USER,
    payload: { model },
    cbSuccess,
    cbError,
  }),

  updateUser: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_USER,
    payload: { model },
    cbSuccess,
    cbError,
  }),
  //#endregion

  resetPassword: (model, cbSuccess, cbError) => ({
    type: actions.USER_RESET_PASSWORD,
    payload: { model },
    cbSuccess,
    cbError,
  }),

  getCustomers: (model, _cbError) => ({
    type: actions.GET_CUSTOMERS,
    payload: { model },
    _cbError,
  }),

  updateCustomer: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_CUSTOMER,
    payload: { model },
    _cbSuccess, _cbError,
  }),

  getCustomerDetail: (id, _cbError) => ({
    type: actions.GET_CUSTOMER_DETAIL,
    payload: { id },
    _cbError,
  }),

  //#region ACCESS PERMISSION
  userAccessPermission: (id) => ({
    type: actions.USER_ACCESS_PERMISSION,
    payload: { id },
  }),

  updateUserAccessPermission: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_USER_ACCESS_PERMISSION,
    payload: { model },
    cbSuccess,
    cbError,
  }),

  savedUserType: (userType) => ({
    type: actions.SAVED_USER_TYPE,
    payload: { userType },
  }),
  //#endregion
};

export default actions;
