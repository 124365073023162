const actions = {
  GET_LIST_ALERTS: "GET_LIST_ALERTS",
  GET_LIST_ALERTS_SUCCESS: "GET_LIST_ALERTS_SUCCESS",
  GET_LIST_ALERTS_ERROR: "GET_LIST_ALERTS_ERROR",

  GET_LIST_ALERT_LOGS: "GET_LIST_ALERT_LOGS",
  GET_LIST_ALERT_LOGS_SUCCESS: "GET_LIST_ALERT_LOGS_SUCCESS",
  GET_LIST_ALERT_LOGS_ERROR: "GET_LIST_ALERT_LOGS_ERROR",

  GET_ALERT_DETAIL: "GET_ALERT_DETAIL",
  GET_ALERT_DETAIL_SUCCESS: "GET_ALERT_DETAIL_SUCCESS",
  GET_ALERT_DETAIL_ERROR: "GET_ALERT_DETAIL_ERROR",

  UPDATE_ALERT: "UPDATE_ALERT",
  UPDATE_COMMENT_ALERT_LOG: "UPDATE_COMMENT_ALERT_LOG",

  GET_TOTAL_ALERTS_NEW: "GET_TOTAL_ALERTS_NEW",
  GET_TOTAL_ALERTS_NEW_SUCCESS: "GET_TOTAL_ALERTS_NEW_SUCCESS",
  GET_TOTAL_ALERTS_NEW_ERROR: "GET_TOTAL_ALERTS_NEW_ERROR",

  RESET_INIT_ID_STORE: "RESET_INIT_ID_STORE",

  EXPORT_ALERT_STATUS: "EXPORT_ALERT_STATUS",

  getListAlerts: (model, _cbError) => ({
    type: actions.GET_LIST_ALERTS,
    payload: { model },
    _cbError,
  }),

  getListAlertLogs: (model, _cbSuccess, _cbError) => ({
    type: actions.GET_LIST_ALERT_LOGS,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  getAlertDetail: (id, _cbError) => ({
    type: actions.GET_ALERT_DETAIL,
    payload: { id },
    _cbError,
  }),

  updateAlert: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_ALERT,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  updateCommentAlertLog: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_COMMENT_ALERT_LOG,
    payload: { model },
    _cbSuccess,
    _cbError
  }),

  getTotalAlertsNew: (_cbError) => ({
    type: actions.GET_TOTAL_ALERTS_NEW,
    _cbError,
  }),

  resetInitIdStore: () => ({
    type: actions.RESET_INIT_ID_STORE,
  }),

  exportAlertStatus: (model, _cbSuccess, _cbError) => ({
    type: actions.EXPORT_ALERT_STATUS,
    payload: { model },
    _cbSuccess,
    _cbError,
  })

};

export default actions;
