// localStorage.js

const USER_TOKEN = "USER_TOKEN";
const USER_DETAIL = "USER_DETAIL";
const USER_SETTING = "USER_SETTING";
export const ALERT_NEWS = "ALERT_NEWS";

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const getData = (key) => {
  try {
    let value = null;
    switch (key) {
      case USER_TOKEN:
        return (value = localStorage.getItem(key));

      case USER_DETAIL:
        return (value = JSON.parse(localStorage.getItem(key)));

      case USER_SETTING:
        return (value = JSON.parse(localStorage.getItem(key)));

      case ALERT_NEWS:
        return (value = JSON.parse(localStorage.getItem(key)));

      default:
        return value;
    }
  } catch (error) {
    return null;
  }
};

export const saveState = (key, value = null) => {
  try {
    let serializedState;

    switch (key) {
      case USER_TOKEN:
        serializedState = value;
        break;

      case USER_DETAIL:
        serializedState = JSON.stringify(value);
        break;

      case USER_SETTING:
        serializedState = JSON.stringify(value);
        break;

      case ALERT_NEWS:
        serializedState = JSON.stringify(value);
        break;

      default:
        break;
    }
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

const deleteData = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
};

export const getToken = () => {
  try {
    const value = getData(USER_TOKEN);
    if (value !== null) {
      return value;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getUserDetail = () => {
  try {
    const value = getData(USER_DETAIL);
    if (value !== null) {
      return value;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const saveUserInfo = (data) => {
  let userDetail = {
    userName: data?.name,
    userType: data?.userType,
    userId: data?.id,
  };
  let setting = {
    auto: false,
    time: 250,
    name: "15s",
  };
  try {
    saveState(USER_TOKEN, data?.token);
    saveState(USER_DETAIL, userDetail);
    saveState(USER_SETTING, setting);
    return true;
  } catch {
    return false;
  }
};

export const deleteToken = () => {
  const result = deleteData(USER_TOKEN);
  return result;
};

export const deleteUserDetail = () => {
  const result = deleteData(USER_DETAIL);
  return result;
};

export const deleteUserSetting = () => {
  const result = deleteData(USER_SETTING);
  return result;
};

export const deleteAlertNews = () => {
  const result = deleteData(ALERT_NEWS);
  return result;
}
