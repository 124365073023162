import * as dashboardService from "@iso/services/dashboard";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

export function* getDashboardDetailSaga() {
  yield takeEvery(
    actions.GET_DASHBOARD_DETAIL,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          dashboardService.getDashboardDetailService,
          model
        );
        if (response.data.success) {
          const { result: data, total: charts } = response.data.data;
          yield put({
            type: actions.GET_DASHBOARD_DETAIL_SUCCESS,
            payload: { data, charts },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_DASHBOARD_DETAIL_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_DASHBOARD_DETAIL_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getCustomerDashboardSaga() {
  yield takeEvery(
    actions.GET_CUSTOMER_DASHBOARD,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          dashboardService.getCustomerDashboardService,
          model
        );
        if (response.data.success) {
          const { data } = response.data;
          yield put({
            type: actions.GET_CUSTOMER_DASHBOARD_SUCCESS,
            payload: { data },
          });
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_CUSTOMER_DASHBOARD_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_CUSTOMER_DASHBOARD_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(getDashboardDetailSaga), fork(getCustomerDashboardSaga)]);
}
