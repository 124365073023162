import actions from "./actions";

const initState = {
  groups: [],
  profileTypes: [],
  provinces: [],
  districts: [],
  wards: [],

  versionApi: "0",
  getCategoriesFlag: null,
  loading: false,

  settings: {
    data: [],
    loading: false,
  },
  categories: {
    loading: false,
    mainCats: [],
    cats: [],
    subCats: [],
    categoryItems: [],
    category: {},
  },
  categoryTree: {
    loading: false,
    list: [],
  },
};

const [MAIN_CATS, CATS, SUB_CATS] = [1, 2, 3];

export default function commonReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_VERSION_API_SUCCESS: {
      return { ...state, versionApi: action.payload.data };
    }
    case actions.GET_CATEGORIES_FLAG: {
      return { ...state, getCategoriesFlag: action.level };
    }
    //#region GROUP
    case actions.GET_GROUPS_COMMON:
      return { ...state };
    case actions.GET_GROUPS_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, groups: res };
    }
    //#endregion

    //#region PROFILE TYPES
    case actions.GET_PROFILE_TYPES_COMMON:
      return { ...state };
    case actions.GET_PROFILE_TYPES_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, profileTypes: res };
    }
    //#endregion

    //#region ADDRESS
    case actions.GET_PROVINCE_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, provinces: res };
    }
    case actions.GET_DISTRICT_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, districts: res };
    }
    case actions.RESET_DISTRICT_COMMON:
      return { ...state, districts: [] };

    case actions.GET_WARD_COMMON_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, wards: res };
    }
    case actions.RESET_WARD_COMMON:
      return { ...state, wards: [] };
    //#endregion

    //#region COMMON SETTINGS
    case actions.GET_COMMON_SETTINGS:
      return { ...state, settings: { ...state.settings, loading: true } };
    case actions.GET_COMMON_SETTINGS_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        settings: { ...state.settings, data: res, loading: false },
      };
    }
    case actions.GET_COMMON_SETTINGS_ERROR:
      return {
        ...state,
        settings: { ...state.settings, loading: false, data: [] },
      };
    //#endregion

    //#region CATEGORIES
    case actions.GET_CATEGORIES:
      return { ...state, categories: { ...state.categories, loading: true } };
    case actions.GET_CATEGORIES_SUCCESS: {
      let { data, level } = action.payload;
      let res = data ? data : [];
      switch (level) {
        case MAIN_CATS:
          return {
            ...state,
            categories: { ...state.categories, mainCats: res, loading: false },
          };
        case CATS:
          return {
            ...state,
            categories: { ...state.categories, cats: res, loading: false },
          };
        case SUB_CATS:
          return {
            ...state,
            categories: { ...state.categories, subCats: res, loading: false },
          };

        default:
          return {
            ...state,
            categories: { ...state.categories, loading: false },
          };
      }
    }
    case actions.GET_CATEGORIES_ERROR:
      return {
        ...state,
        categories: { ...state.categories, loading: false },
      };

    case actions.RESET_CATEGORIES:
      return {
        ...state,
        categories: { ...state.categories, cats: [], subCats: [] },
      };

    case actions.RESET_SUB_CATEGORIES:
      return {
        ...state,
        categories: { ...state.categories, subCats: [] },
      };
    //#endregion

    //#region CATEGORY ITEMS
    case actions.GET_CATEGORY_ITEMS:
      return {
        ...state,
        categories: { ...state.categories, loading: true },
      };

    case actions.GET_CATEGORY_ITEMS_SUCCESS:
      const { data } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        categories: { ...state.categories, loading: false, categoryItems: res },
      };

    case actions.GET_CATEGORY_ITEMS_ERROR:
      return {
        ...state,
        categories: { ...state.categories, loading: false, categoryItems: [] },
      };
    //#endregion

    //#region COMMON CATEGORY TREE
    case actions.GET_CATEGORY_TREE:
      return {
        ...state,
        categoryTree: { ...state.categoryTree, loading: true },
      };
    case actions.GET_CATEGORY_TREE_SUCCESS: {
      const { data } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        categoryTree: { ...state.categoryTree, list: res, loading: false },
      };
    }
    case actions.GET_CATEGORY_TREE_ERROR:
      return {
        ...state,
        categoryTree: { ...state.categoryTree, loading: false, list: [] },
      };
    //#endregion

    //#region CATEGORIES FROM ITEM
    case actions.GET_CATEGORIES_FROM_ITEM:
      return { ...state, categories: { ...state.categories, loading: true } };

    case actions.GET_CATEGORIES_FROM_ITEM_SUCCESS: {
      const { data } = action.payload;
      const {
        grandpaId,
        parentId,
        subCategoryId,
        listGrandpa,
        listParent,
        listSubCategories,
      } = data;
      let obj = {};
      if (grandpaId && listGrandpa?.length) {
        let mainArr = listGrandpa.filter((item) => item.id === grandpaId);
        obj["mainId"] = mainArr[0].id;
        obj["mainName"] = mainArr[0].name;
      }
      if (parentId && listParent?.length) {
        let cateArr = listParent.filter((item) => item.id === parentId);
        obj["cateId"] = cateArr[0].id;
        obj["cateName"] = cateArr[0].name;
      }
      if (subCategoryId && listSubCategories?.length) {
        let subArr = listSubCategories.filter(
          (item) => item.id === subCategoryId
        );
        obj["subId"] = subArr[0].id;
        obj["subName"] = subArr[0].name;
      }
      return {
        ...state,
        categories: {
          ...state.categories,
          // mainCats: listGrandpa,
          cats: listParent,
          subCats: listSubCategories,
          category: obj,
          loading: false,
        },
      };
    }
    case actions.GET_CATEGORIES_FROM_ITEM_ERROR:
      return { ...state, categories: { ...state.categories, loading: false } };
    //#endregion

    //#region CLEAR STORAGE
    case actions.CLEAR_COMMON_STORAGE:
      return { ...state, settings: { ...state.settings, data: [] } };
    //#endregion

    case actions.COMMON_RESPONSE_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
