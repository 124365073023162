import actions from './actions';

const initState = {
  users: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  userDetail: {
    detail: {},
    loading: false,
  },
  permissions: {},
  customers: {
    list: [],
    loading: false,
    totalItems: 0,
  },
  customerDetail: {
    detail: {},
    loading: false,
  },
  userType: null,
};

export default function userReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_USERS:
      return { ...state, users: { ...state.users, loading: true } };
    case actions.GET_USERS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, users: { ...state.users, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_USERS_ERROR:
      return { ...state, users: { ...state.users, loading: false, totalItems: 0, list: [] } };

    case actions.GET_USER_DETAIL:
      return { ...state, userDetail: { ...state.userDetail, loading: true } };
    case actions.GET_USER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, userDetail: { ...state.userDetail, loading: false, detail: res } };
    }
    case actions.GET_USER_DETAIL_ERROR:
      return { ...state, userDetail: { ...state.userDetail, loading: false } };
    //#endregion

    //#region ACCESS PERMISSION
    case actions.USER_ACCESS_PERMISSION:
      return { ...state, loading: true };
    case actions.USER_ACCESS_PERMISSION_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, permissions: res, loading: false };
    }
    case actions.USER_ACCESS_PERMISSION_ERROR:
      return { ...state, loading: false };
    //#endregion

    //#region CUSTOMERS
    case actions.GET_CUSTOMERS:
      return { ...state, customers: { ...state.customers, loading: true } };
    case actions.GET_CUSTOMERS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, customers: { ...state.customers, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_CUSTOMERS_ERROR:
      return { ...state, customers: { ...state.customers, loading: false, totalItems: 0, list: [] } };

    case actions.GET_CUSTOMER_DETAIL:
      return { ...state, customerDetail: { ...state.customerDetail, loading: true } };
    case actions.GET_CUSTOMER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, customerDetail: { ...state.customerDetail, loading: false, detail: res } };
    }
    case actions.GET_CUSTOMER_DETAIL_ERROR:
      return { ...state, customerDetail: { ...state.customerDetail, loading: false, detail: {} } };
    //#endregion

    //#region USER TYPE
    case actions.SAVED_USER_TYPE:
      const { userType } = action.payload;
      return { ...state, userType: userType };
    //#endregion

    default:
      return state;

  }
}
