import alertSaga from "@iso/redux/alerts/sagas";
import authSaga from "@iso/redux/auth/saga";
import commonSaga from "@iso/redux/common/saga";
import dashboardSaga from "@iso/redux/dashboard/sagas";
import userSaga from "@iso/redux/users/saga";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    authSaga(),
    commonSaga(),
    userSaga(),
    alertSaga(),
    dashboardSaga(),
  ]);
}
