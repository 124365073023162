import Alerts from "@iso/redux/alerts/reducer";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Common from "@iso/redux/common/reducer";
import Dashboard from "@iso/redux/dashboard/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import User from "@iso/redux/users/reducer";
import { combineReducers } from "redux";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Common,
  User,
  Alerts,
  Dashboard,
});
