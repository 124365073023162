import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getUsers = (body) => {
  var url = `${ApiRouters.AGENCY}/user`;
  return api.get(url, { params: body });
};
export const deleteUser = (model) => {
  var url = `${ApiRouters.AGENCY}`;
  return api.delete(url, { params: model });
};
export const getUserDetail = (id) => {
  var url = `${ApiRouters.AGENCY}/${id}`;
  return api.get(url);
};
export const createUser = (body) => {
  var url = ApiRouters.AGENCY;
  return api.post(url, body);
};
export const updateUser = (body) => {
  var url = `${ApiRouters.AGENCY}/${body.id}`;
  return api.put(url, body);
};
export const resetPassword = (body) => {
  var url = `${ApiRouters.AGENCY}/resetPassword/${body.id}`;
  return api.put(url);
};
export const userAccessPermission = (id) => {
  var url = `${ApiRouters.AGENCY}/${id}/Roles`;
  return api.get(url);
};
export const updateUserAccessPermission = (body) => {
  var url = `${ApiRouters.AGENCY}/${body.id}/Roles`;
  return api.put(url, body);
};
export const getCustomersService = (model) => {
  var url = `${ApiRouters.AGENCY}/customer`;
  return api.get(url, { params: model });
};
export const getCustomerDetailService = (id) => {
  var url = `${ApiRouters.AGENCY}/get-customer-info/${id}`;
  return api.get(url);
};
export const updateCustomerService = (model) => {
  var url = `${ApiRouters.AGENCY}/customer/${model.id}`;
  return api.put(url, model.body);
};
