const actions = {
  GET_DASHBOARD_DETAIL: "GET_DASHBOARD_DETAIL",
  GET_DASHBOARD_DETAIL_SUCCESS: "GET_DASHBOARD_DETAIL_SUCCESS",
  GET_DASHBOARD_DETAIL_ERROR: "GET_DASHBOARD_DETAIL_ERROR",

  getDashboardDetail: (model, _cbError) => ({
    type: actions.GET_DASHBOARD_DETAIL,
    payload: { model },
    _cbError,
  }),

  GET_CUSTOMER_DASHBOARD: "GET_CUSTOMER_DASHBOARD",
  GET_CUSTOMER_DASHBOARD_SUCCESS: "GET_CUSTOMER_DASHBOARD_SUCCESS",
  GET_CUSTOMER_DASHBOARD_ERROR: "GET_CUSTOMER_DASHBOARD_ERROR",

  getCustomerDashboard: (model, _cbSuccess, _cbError) => ({
    type: actions.GET_CUSTOMER_DASHBOARD,
    payload: { model },
    _cbError,
    _cbSuccess,
  }),
}

export default actions;