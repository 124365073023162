import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getDashboardDetailService = (model) => {
  var url = `${ApiRouters.DASHBOARD}/alert`;
  return api.get(url, { params: model });
};

export const getCustomerDashboardService = (model) => {
  var url = `${ApiRouters.DASHBOARD}/map-alert-detail`;
  return api.get(url, { params: model });
}