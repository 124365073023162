import * as userServices from "@iso/services/user";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region LIST
export function* getUsers() {
  yield takeEvery(actions.GET_USERS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(userServices.getUsers, model);
      if (response.data.success) {
        var data = response.data.data.list;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_USERS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_USERS_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_USERS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteUser() {
  yield takeEvery(
    actions.DELETE_USER,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(userServices.deleteUser, model);
        if (response.data.success === true) {
          yield cbSuccess();
        } else {
          const { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}

export function* getUserDetail() {
  yield takeEvery(actions.GET_USER_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(userServices.getUserDetail, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_USER_DETAIL_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_USER_DETAIL_ERROR,
      });
    }
  });
}

export function* createUser() {
  yield takeEvery(
    actions.CREATE_USER,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(userServices.createUser, model);
        if (response.data.success) {
          let { data: userId } = response.data;
          yield cbSuccess(userId);
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}

export function* updateUser() {
  yield takeEvery(
    actions.UPDATE_USER,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(userServices.updateUser, model);
        if (response.data.success === true) {
          yield cbSuccess();
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}
//#endregion

export function* resetPassword() {
  yield takeEvery(
    actions.USER_RESET_PASSWORD,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(userServices.resetPassword, model);
        if (response.data.success) {
          var password = response.data.data;
          yield cbSuccess(password);
        } else {
          let { message } = response.data;
          yield cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}

//#region ACCESS PERMISSION
export function* userAccessPermission() {
  yield takeEvery(actions.USER_ACCESS_PERMISSION, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(userServices.userAccessPermission, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.USER_ACCESS_PERMISSION_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({
        type: actions.USER_ACCESS_PERMISSION_ERROR,
      });
    }
  });
}

export function* updateUserAccessPermission() {
  yield takeEvery(
    actions.UPDATE_USER_ACCESS_PERMISSION,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          userServices.updateUserAccessPermission,
          model
        );
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}

export function* getCustomersSaga() {
  yield takeEvery(actions.GET_CUSTOMERS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(userServices.getCustomersService, model);
      if (response.data.success) {
        const { list: data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_CUSTOMERS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_CUSTOMERS_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_CUSTOMERS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* getCustomerDetailSaga() {
  yield takeEvery(
    actions.GET_CUSTOMER_DETAIL,
    function* ({ payload, _cbError }) {
      const { id } = payload;
      try {
        var response = yield call(userServices.getCustomerDetailService, id);
        if (response.data.success) {
          const { data } = response.data;
          yield put({
            type: actions.GET_CUSTOMER_DETAIL_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_CUSTOMER_DETAIL_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_CUSTOMER_DETAIL_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateCustomerSaga() {
  yield takeEvery(
    actions.UPDATE_CUSTOMER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(userServices.updateCustomerService, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          const { message } = response.data;
          _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getUsers),
    fork(deleteUser),
    fork(getUserDetail),
    fork(createUser),
    fork(updateUser),
    fork(resetPassword),
    fork(userAccessPermission),
    fork(updateUserAccessPermission),
    fork(getCustomersSaga),
    fork(getCustomerDetailSaga),
    fork(updateCustomerSaga),
  ]);
}
