import PageSize from "@iso/constants/PageSize";
import actions from "./actions";

const initState = {
  alerts: [],
  totalItems: 0,
  loading: false,
  totalAlerts: {},
  alertStatus: null,
  alertType: null,
  alertLogs: [],
  alertDetail: {},
  totalAlertsNew: {},
  initId: 1,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    //#region ALERTS
    case actions.GET_LIST_ALERTS:
      return { ...state, loading: true };

    case actions.GET_LIST_ALERTS_SUCCESS: {
      let { data, totalItems, pageIndex, each_status_total } = action.payload;
      let res = data ? data : [];
      let array = [];
      if (res?.length) {
        res.map((item, index) => {
          return array.push({
            ...item,
            serial: PageSize.alerts * (pageIndex - 1) + index + 1,
          });
        });
      }
      return {
        ...state,
        alerts: array,
        loading: false,
        totalItems: totalItems,
        totalAlerts: each_status_total,
      };
    }
    case actions.GET_LIST_ALERTS_ERROR:
      return {
        ...state,
        loading: false,
        totalItems: 0,
        alerts: [],
        totalAlerts: {},
      };

    case actions.GET_TOTAL_ALERTS_NEW:
      return { ...state, loading: true };

    case actions.GET_TOTAL_ALERTS_NEW_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return {
        ...state,
        totalAlertsNew: res,
        loading: false,
      };
    }
    case actions.GET_TOTAL_ALERTS_NEW_ERROR:
      return {
        ...state,
        loading: false,
        totalAlertsNew: {},
      };
    //#endregion

    //#region LOGS
    case actions.GET_LIST_ALERT_LOGS:
      return { ...state, loading: true, alertLogs: [] };

    case actions.GET_LIST_ALERT_LOGS_SUCCESS: {
      let { data, totalItems, pageIndex, alertStatus, alertType } =
        action.payload;
      let res = data ? data : [];
      let array = [];
      if (res?.length) {
        res.map((item, index) => {
          return array.push({
            ...item,
            serial: PageSize.alerts * (pageIndex - 1) + index + 1,
          });
        });
      }
      return {
        ...state,
        alertLogs: array,
        loading: false,
        totalItems: totalItems,
        alertStatus: alertStatus,
        alertType: alertType,
      };
    }
    case actions.GET_LIST_ALERT_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        totalItems: 0,
        alertLogs: [],
        alertStatus: null,
        alertType: null,
      };
    //#endregion

    //#region ALERT DETAIL
    case actions.GET_ALERT_DETAIL:
      return { ...state, loading: true, alertDetail: {} };

    case actions.GET_ALERT_DETAIL_SUCCESS:
      const { data } = action.payload;
      let res = data?.id ? data : {};
      let location = {
        alert_code: res?.alert_code,
        id: res?.id,
        latitude: res.latitude,
        longitude: res.longitude,
        firstName: res?.people?.createdBy?.firstname,
        lastName: res?.people?.createdBy?.lastname,
        phoneNumber: res?.people?.createdBy?.phone,
        gender: res?.people?.createdBy?.gender,
        address: res?.address,
        created_by: res?.people?.createdBy?.id,
        alert_type: res?.alert_type,
      };
      return {
        ...state,
        loading: false,
        alertDetail: { ...res, locations: [location] },
        initId: data?.id,
      };

    case actions.GET_ALERT_DETAIL_ERROR:
      return { ...state, loading: false, alertDetail: {}, initId: 1 };
    //#endregion

    case actions.RESET_INIT_ID_STORE:
      return { ...state, initId: 1 };

    default:
      return state;
  }
}
