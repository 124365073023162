import * as alertService from "@iso/services/alerts";
import moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region ALERTS
export function* getListAlertsSaga() {
  yield takeEvery(actions.GET_LIST_ALERTS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(alertService.getLisetAlertsService, model);
      if (response.data.success) {
        const {
          list: data,
          total: totalItems,
          page: pageIndex,
          each_status_total,
        } = response.data.data;
        yield put({
          type: actions.GET_LIST_ALERTS_SUCCESS,
          payload: { data, totalItems, pageIndex, each_status_total },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_LIST_ALERTS_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_LIST_ALERTS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* getListAlertLogsSaga() {
  yield takeEvery(
    actions.GET_LIST_ALERT_LOGS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(alertService.getListAlertLogsService, model);
        if (response.data.success) {
          const {
            list: data,
            total: totalItems,
            page: pageIndex,
            alert_status: alertStatus,
            alert_type: alertType,
          } = response.data.data;

          yield put({
            type: actions.GET_LIST_ALERT_LOGS_SUCCESS,
            payload: { data, totalItems, pageIndex, alertStatus, alertType },
          });
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_LIST_ALERT_LOGS_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_LIST_ALERT_LOGS_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getAlertDetailSaga() {
  yield takeEvery(actions.GET_ALERT_DETAIL, function* ({ payload, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(alertService.getAlertDetailService, id);
      if (response.data.success) {
        const { data } = response.data;

        yield put({
          type: actions.GET_ALERT_DETAIL_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_ALERT_DETAIL_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_ALERT_DETAIL_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* updateAlertSaga() {
  yield takeEvery(
    actions.UPDATE_ALERT,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(alertService.updateAlertService, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateCommentAlertLogSaga() {
  yield takeEvery(
    actions.UPDATE_COMMENT_ALERT_LOG,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(alertService.updateCommentAlertLogService, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getTotalAlertsNewSaga() {
  yield takeEvery(actions.GET_TOTAL_ALERTS_NEW, function* ({ _cbError }) {
    try {
      var response = yield call(alertService.getTotalAlertsNewService);
      if (response.data.success) {
        const { data } = response.data;
        yield put({
          type: actions.GET_TOTAL_ALERTS_NEW_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_TOTAL_ALERTS_NEW_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_TOTAL_ALERTS_NEW_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* exportAlertStatusSaga() {
  yield takeEvery(
    actions.EXPORT_ALERT_STATUS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          alertService.exportAlertStatusService, model
        );

        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `${model.alert_name}_${`${date}`}.csv`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data) yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

//#endregion

export default function* rootSaga() {
  yield all([
    fork(getListAlertsSaga),
    fork(getAlertDetailSaga),
    fork(getListAlertLogsSaga),
    fork(updateAlertSaga),
    fork(updateCommentAlertLogSaga),
    fork(getTotalAlertsNewSaga),
    fork(exportAlertStatusSaga),
  ]);
}
