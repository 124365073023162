const baseRouter = "/api/";

export const ApiRouters = {
  LOGIN: baseRouter + "Auth/login",
  FORGOT_PASSWORD: baseRouter + "Auth/ForgotPassword",
  CHECK_TOKEN_RESET_PW: baseRouter + "Auth/CheckResetPassword",
  RESET_PASSWORD: baseRouter + "Auth/ResetPassword",

  AGENCY: baseRouter + "Agency",
  COMMON: baseRouter + "Common",
  BRANDS: baseRouter + "Brand",
  ALERTS: baseRouter + "Alert",
  DASHBOARD: baseRouter + "Dashboard",

};
