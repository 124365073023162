import * as commonServices from "@iso/services/common";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region VERSION
export function* getVersionApi() {
  yield takeEvery(actions.GET_VERSION_API, function* ({ payload }) {
    try {
      var response = yield call(commonServices.getVersionApi);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_VERSION_API_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_VERSION_API_ERROR });
    }
  });
}
//#endregion

//#region GROUP
export function* getGroupsCommon() {
  yield takeEvery(actions.GET_GROUPS_COMMON, function* ({ payload }) {
    try {
      var response = yield call(commonServices.getGroupsCommon);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_GROUPS_COMMON_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR });
    }
  });
}
//#endregion

//#region GROUP
export function* getProfileTypesCommon() {
  yield takeEvery(actions.GET_PROFILE_TYPES_COMMON, function* ({ payload }) {
    try {
      var response = yield call(commonServices.getProfileTypesCommon);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_PROFILE_TYPES_COMMON_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR });
    }
  });
}
//#endregion

//#region ADDRESS
export function* getProvinces() {
  yield takeEvery(actions.GET_PROVINCE_COMMON, function* () {
    try {
      var response = yield call(commonServices.getProvinces);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_PROVINCE_COMMON_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR });
    }
  });
}
export function* getDistricts() {
  yield takeEvery(actions.GET_DISTRICT_COMMON, function* ({ payload }) {
    const { provinceId } = payload;
    try {
      var response = yield call(commonServices.getDistricts, provinceId);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_DISTRICT_COMMON_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR });
    }
  });
}
export function* getWards() {
  yield takeEvery(actions.GET_WARD_COMMON, function* ({ payload }) {
    const { districtId } = payload;
    try {
      var response = yield call(commonServices.getWards, districtId);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_WARD_COMMON_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.COMMON_RESPONSE_ERROR });
    }
  });
}
//#endregion

//#region SETTINGS
export function* getCommonSettingsSaga() {
  yield takeEvery(actions.GET_COMMON_SETTINGS, function* ({ _cbError }) {
    try {
      var response = yield call(commonServices.getCommonSettingsService);
      if (response.data?.success) {
        var data = response.data?.data;
        yield put({
          type: actions.GET_COMMON_SETTINGS_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_COMMON_SETTINGS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* updateCommonSettingsSaga() {
  yield takeEvery(
    actions.UPDATE_COMMON_SETTINGS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          commonServices.updateCommonSettingsService,
          model
        );
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

//#region CATEGORY
export function* getCommonCategoriesSaga() {
  yield takeEvery(actions.GET_CATEGORIES, function* ({ payload, _cbError }) {
    try {
      const { model } = payload;
      var response = yield call(commonServices.getCategories, model);
      if (response.data?.success) {
        var data = response.data?.data;
        var level = model.level;
        yield put({
          type: actions.GET_CATEGORIES_SUCCESS,
          payload: { data, level },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_CATEGORIES_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_CATEGORIES_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* getCategoryItemsSaga() {
  yield takeEvery(
    actions.GET_CATEGORY_ITEMS,
    function* ({ payload, _cbError }) {
      try {
        const { model } = payload;
        var response = yield call(
          commonServices.getCategoryItemsService,
          model
        );
        if (response.data?.success) {
          var data = response.data?.data;
          yield put({
            type: actions.GET_CATEGORY_ITEMS_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_CATEGORY_ITEMS_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_CATEGORY_ITEMS_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getCategoryTreeSaga() {
  yield takeEvery(actions.GET_CATEGORY_TREE, function* ({ _cbError }) {
    try {
      var response = yield call(commonServices.getCategoryTreeService);
      if (response.data?.success) {
        var data = response.data?.data;
        yield put({
          type: actions.GET_CATEGORY_TREE_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_CATEGORY_TREE_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_CATEGORY_TREE_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* getCategoriesFromItemSaga() {
  yield takeEvery(
    actions.GET_CATEGORIES_FROM_ITEM,
    function* ({ payload, _cbError }) {
      try {
        const { itemId } = payload;
        var response = yield call(
          commonServices.getCategoriesFromItemService,
          itemId
        );
        if (response.data?.success) {
          var data = response.data?.data;
          yield put({
            type: actions.GET_CATEGORIES_FROM_ITEM_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_CATEGORIES_FROM_ITEM_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_CATEGORIES_FROM_ITEM_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export function* getCommonBrandsSaga() {
  yield takeEvery(actions.GET_COMMON_BRANDS, function* ({ _cbError }) {
    try {
      var response = yield call(commonServices.getCommonBrandsService);
      if (response.data?.success) {
        var data = response.data?.data;
        yield put({
          type: actions.GET_COMMON_BRANDS_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_COMMON_BRANDS_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_COMMON_BRANDS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getVersionApi),
    fork(getGroupsCommon),
    fork(getProfileTypesCommon),

    fork(getProvinces),
    fork(getDistricts),
    fork(getWards),

    fork(getCommonSettingsSaga),
    fork(updateCommonSettingsSaga),

    fork(getCommonCategoriesSaga),
    fork(getCategoryItemsSaga),
    fork(getCategoryTreeSaga),
    fork(getCategoriesFromItemSaga),

    fork(getCommonBrandsSaga),
  ]);
}
