import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 300000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}

//#region ALERTS
export const getLisetAlertsService = (model) => {
  let url = ApiRouters.ALERTS;
  return api.get(url, { params: model });
};

export const getListAlertLogsService = (model) => {
  let url = `${ApiRouters.ALERTS}/${model.id}/logs`;
  return api.get(url, { params: model });
};

export const getAlertDetailService = (id) => {
  let url = `${ApiRouters.ALERTS}/${id}`;
  return api.get(url);
};

export const updateAlertService = (model) => {
  let url = `${ApiRouters.ALERTS}/${model.id}`;
  return api.put(url, model);
};

export const updateCommentAlertLogService = (model) => {
  let url = `${ApiRouters.ALERTS}/${model.id}/log/comment`;
  return api.put(url, model.body);
};

export const getTotalAlertsNewService = () => {
  let url = `${ApiRouters.ALERTS}/global-alert`;
  return api.get(url);
};

export const exportAlertStatusService = (model) => {
  let url =
    process.env.REACT_APP_API_KEY + `${ApiRouters.ALERTS}/exportcsv`;
  return axios(optionsAxios(url, "get", model, null));
}


